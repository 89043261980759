import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ImSwitch } from "react-icons/im";
import { COLORS } from "../../../../utils/Style/styleGlobale";
import { Dynamic } from "../../../../Context/ContextDynamic/ContextDynamic";
import { toast } from "react-toastify";
import axios from "axios";
import { SocketContext } from "../../../../Context/ContextSocket/ContextSocket";

const FastCodeGenere = () => {
  const [actif, setActif] = useState(true);
  const { user, setUser, goPage, codeTemporaire, setCodeTemporaire } =
    Dynamic();
  const { socket } = SocketContext();
  const genereCodeFastActif = async () => {
    if (user.codeactif === 0)
      return toast.info("Activé le system avant de générer un code");
    try {
      const res = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API}user/genere/code/fast/${user._id}`,
        withCredentials: true,
      });
      //   console.log(res);
      if (res.data.error) return toast.error(res.data.error);
      if (res.data.message) {
        toast.success("Nouveau CodeFast");
        socket.emit("code-fast", { id: user._id });
        socket.off("code-fast");
        return setCodeTemporaire(res.data.message);
      }
    } catch (error) {
      console.log(error);
      return toast.error("Oups, erreur inattendue est survenue 🤧");
    }
  };
  const actifOrNot = async () => {
    try {
      const res = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API}user/actifornot/code/fast/${user._id}`,
        withCredentials: true,
        data: { actif },
      });
      // console.log(res);
      if (res.data.error) return toast.error(res.data.error);
      if (res.data.newuser) {
        if (res.data.newuser.codeactif === 0) {
          setUser(res.data.newuser);
          return toast.success("Code d'identification désactivé");
        }
        if (res.data.newuser.codeactif === 1) {
          setUser(res.data.newuser);
          return toast.success("Code d'identification activé");
        }
      }
    } catch (error) {
      console.log(error);
      return toast.error("Oups, erreur inattendue est survenue 🤧");
    }
  };

  useEffect(() => {
    if (user && user.codefast === "0") {
      setCodeTemporaire("Actif mais pas de code");
    } else {
      setCodeTemporaire(user.codefast);
    }
  }, [user]);
  return (
    <StyledFastCodeGenere $css={user.codeactif}>
      <h1>Identification</h1>
      <div className="sous-box-genere">
        <span>
          Ce code est à partager avec vos clients afin qu'ils puissent
          identifier votre enseigne et vos produits actifs
        </span>
        <strong>{user.codeactif === 0 ? "Non actif" : codeTemporaire}</strong>
        <strong className="ge-code" onClick={() => genereCodeFastActif()}>
          Générer un nouveau code
        </strong>
        <ImSwitch className="the-switch" onClick={() => actifOrNot()} />
        <span className="the-span-to-watch" onClick={() => goPage("/code/id")}>
          Voir la page Code d'Identification pour les clients
        </span>
      </div>
    </StyledFastCodeGenere>
  );
};

export default FastCodeGenere;
const StyledFastCodeGenere = styled.div`
  padding: 5px;
  /* background: blue; */
  width: 70%;
  h1 {
    text-align: center;
    /* letter-spacing: 5px; */
  }
  .sous-box-genere {
    position: relative;
    background: ${COLORS.greenTransparent};
    border-radius: 5px;
    padding: 5px;
    margin: 5px auto;
    width: 50%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* align-items: center; */
    span {
      text-align: center;
    }
    strong {
      text-align: center;
      font-size: 2.5em;
    }
    .ge-code {
      background: ${COLORS.greenTransparent};
      box-shadow: 7px 7px 15px #8e9391, -7px -7px 15px #8e9391;
      padding: 5px;
      font-size: 1.2em;
      border-radius: 5px;
      color: white;
      text-align: center;
      font-weight: 800;
      cursor: pointer;
      margin: 20px 0px;
    }
    .ge-code:active {
      box-shadow: inset 7px 7px 15px #02b065, inset -7px -7px 15px #04ff95;
    }
    .the-switch {
      position: absolute;
      bottom: 10px;
      left: 10px;
      font-size: 1.7em;
      color: ${({ $css }) => ($css === 0 ? COLORS.green : COLORS.red)};
      background: ${COLORS.blackgrey};
      padding: 3px;
      border-radius: 5px;
    }
    .the-span-to-watch {
      text-align: right;
      font-size: 0.8em;
      color: white;
    }
  }

  //width >= 425px
  @media screen and (max-width: 445px) {
    margin-top: 10px;
    width: 100%;
    h1 {
      font-size: 1.1em;
    }
    .sous-box-genere {
      width: 100%;
    }
  }
`;
