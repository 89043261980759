import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../utils/Style/styleGlobale";

const Presentation = () => {
  return (
    <StyledPresentation>
      <h2>Présentation</h2>
      <div className="cards">
        <div className="proposition">
          <strong>Pourquoi vendre via internet ?</strong>
          <p>
            ⭐ Vendre via Internet est devenu incontournable avec la nouvelle
            génération, qui privilégie les achats en ligne pour leur rapidité,
            leur praticité et la diversité des choix. Avec les réseaux sociaux
            et les plateformes e-commerce, les jeunes consommateurs découvrent
            et achètent des produits en quelques clics
          </p>
        </div>
        <div className="proposition">
          <strong>Pour qui ?</strong>
          <span>⭐ Particuliers dans la restauration </span>
          <span>⭐ Professionnels dans la restauration</span>
          <p>⭐Accès sans engagement⭐</p>
        </div>
        <div className="proposition">
          <strong>Services :</strong>
          <span>⭐ Recevoir les commandes en temps réel</span>
          <span>
            ⭐ Informer en temps réel si la commande a été accepté/refusé/prête
          </span>
          <span>⭐ Obtenir itinéraire si livraison</span>
          <span>⭐ Eviter fil d'attente de client (bien-être du client) </span>
          <span>
            ⭐ Informer que vous n'êtes plus en service (bien-être du client){" "}
          </span>
          <span>⭐ Permet de savoir le CA de la journée</span>
        </div>
      </div>
    </StyledPresentation>
  );
};

export default Presentation;

const StyledPresentation = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 55px;
  h2 {
    text-align: center;
    font-size: 2em;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .proposition {
      display: flex;
      flex-direction: column;
      max-width: 25%;
      padding: 10px;
      margin: 10px;
      background: ${COLORS.green};
      border-radius: 25px;
      p {
        text-align: center;
      }
      span,
      p {
        margin-top: 5px;
        color: white;
      }
      strong {
        font-size: 1.3em;
        text-align: center;
        margin-bottom: 10px;
        color: ${COLORS.checkOrder};
      }
    }
  }
  @media screen and (max-width: 445px) {
    .cards {
      .proposition {
        max-width: 80%;
      }
    }
  }
`;
