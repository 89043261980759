import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dynamic } from "../../../Context/ContextDynamic/ContextDynamic";
import { Data } from "../../../Context/ContextData/ContextData";
import Order from "./OrderCurrent/Order";
import { COLORS } from "../../../utils/Style/styleGlobale";
import { MdDelete } from "react-icons/md";
import { FaCheckSquare } from "react-icons/fa";
import { FaThumbsUp } from "react-icons/fa6";
import { IoHandLeftSharp } from "react-icons/io5";
import { LuRefreshCw } from "react-icons/lu";
import { motion } from "framer-motion";

const OrdersCurrent = () => {
  const [validOrder, setValidOrder] = useState(false);
  const [rotation, setRotation] = useState(0);
  const { user } = Dynamic();
  const { ordersData, oldOrdersData, alertNewOrder, getAllOrders } = Data();

  const ordersNotValide = () => {
    {
      return (
        ordersData &&
        ordersData.length !== 0 &&
        ordersData
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map((order) => (
            <Order
              key={order._id}
              codefast={order.codefast}
              idClientFast={order.idClientFast}
              table={order.table}
              note={order.note}
              order={order.order}
              idOrder={order._id}
              statusOrder={order.status}
              localisation={order.localisation}
              telephone={order.telephone}
              dateTime={order.createdAt}
            />
          ))
      );
    }
  };
  const ordervalid = () => {
    const li = <li>Aucune commande validée</li>;
    return oldOrdersData
      ? oldOrdersData.length !== 0 &&
          oldOrdersData
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            .map((order) => (
              <Order
                key={order._id}
                codefast={order.codefast}
                idClientFast={order.idClientFast}
                table={order.table}
                note={order.note}
                order={order.order}
                idOrder={order._id}
                statusOrder={order.status}
                localisation={order.localisation}
                telephone={order.telephone}
                dateTime={order.createdAt}
              />
            ))
      : li;
  };

  //calcule price to all order
  const calculeAllOrder = () => {
    let total = 0;
    ordersData &&
      ordersData.length !== 0 &&
      ordersData.forEach((order) => {
        // console.log(order);
        if (order.totalOrder) {
          total += order.totalOrder;
        }
      });
    return `Total en cours : ${total.toFixed(2)} €`;
  };
  const calculeAllOrderValided = () => {
    let total = 0;
    oldOrdersData &&
      oldOrdersData.length !== 0 &&
      oldOrdersData.forEach((order) => {
        // console.log(order);
        if (order.totalOrder) {
          total += order.totalOrder;
        }
      });
    return `Total validé : ${total.toFixed(2)} €`;
  };

  const displayNumberOrdersCurrentOrOldOrders = () => {
    const oldOrders = oldOrdersData.length;
    const ordersCurrennt = ordersData.length;
    const infoSend = validOrder ? oldOrders : ordersCurrennt;
    const textConditionOrdersCurrent =
      ordersCurrennt > 1
        ? infoSend + " commandes en cours"
        : infoSend + " commande en cours";
    const textConditionOrdersValided =
      oldOrders > 1
        ? infoSend + " commandes validées"
        : infoSend + " commande validée";
    const legendText = validOrder
      ? textConditionOrdersValided
      : textConditionOrdersCurrent;
    return legendText;
  };

  const refreshOrdersNotValide = () => {
    //setRotation est parametré ainsi pour
    //que ça tourne toujours vers la droite
    setRotation(rotation + 360);
    getAllOrders();
  };

  useEffect(() => {
    setRotation(rotation + 360);
    setValidOrder(false);
  }, []);

  return (
    <StyledOrdersCurrent>
      <div className="in-orders-current">
        <div className="box-info-help">
          <div className="box-icon-help-info">
            <IoHandLeftSharp className="icov d" />
            <span>Refuser</span>
          </div>
          <div className="box-icon-help-info">
            <FaThumbsUp className="icov r" />
            <span>Accepter</span>
          </div>
          <div className="box-icon-help-info">
            <FaCheckSquare className="icov v" />
            <span>Valider</span>
          </div>
          <div className="box-icon-help-info">
            <MdDelete className="icov d" />
            <span>Supprimer</span>
          </div>
        </div>
        <div className="box-to-btn-home">
          <button onClick={() => setValidOrder(!validOrder)}>
            {validOrder
              ? "Voir les commandes en cours"
              : "Voir les commandes validées"}
          </button>
          {!validOrder && (
            <motion.div
              className="box-to-icon-refresh"
              animate={{ rotate: rotation }}
              transition={{ duration: 0.8, ease: "linear" }}
              onClick={() => refreshOrdersNotValide()}
            >
              <LuRefreshCw className="refresh-i" />
            </motion.div>
          )}
        </div>
        <h1 className="title-home">
          {displayNumberOrdersCurrentOrOldOrders()}{" "}
        </h1>
        {
          <h2 className="title-second-h2">
            {!validOrder ? calculeAllOrder() : calculeAllOrderValided()}{" "}
          </h2>
        }
        <ul>{validOrder ? ordervalid() : ordersNotValide()}</ul>
      </div>
    </StyledOrdersCurrent>
  );
};

export default OrdersCurrent;

const StyledOrdersCurrent = styled.div`
  background: white;
  /* padding-top: 10vh; */
  margin-top: 15vh;
  width: 100%;
  height: 100vh;
  .title-home {
    font-size: 1.3em;
    /* color: ${COLORS.btnColor}; */
    text-align: center;
  }
  .title-second-h2 {
    margin-left: 10px;
    font-size: 1.2em;
  }
  .in-orders-current {
    width: 100%;
    .box-info-help {
      margin: 15px 0px;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      .box-icon-help-info {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        border-radius: 7px;
        background: ${COLORS.greenTransparent};
        span {
          color: ${COLORS.btnshadow};
        }
        .icov {
          font-size: 1.1em;
          margin-right: 5px;
        }
        .icov.d {
          color: ${COLORS.red};
        }
        .icov.r {
          color: ${COLORS.facebook};
        }
        .icov.v {
          color: ${COLORS.checkOrder};
        }
      }
    }
  }
  .box-to-btn-home {
    padding: 0px 10px;
    font-size: 0.8em;
    display: flex;
    display: flex;
    align-items: center;
    .box-to-icon-refresh {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 30px;
      .refresh-i {
        font-weight: 900;
        font-size: 2em;
        color: ${COLORS.checkOrder};
        cursor: pointer;
      }
    }
  }
  .box-to-btn-home > button:last-child {
    color: orange;
  }
  .box-to-btn-home > button {
    padding: 5px;
    border: none;
    outline: none;
    border-radius: 5px;
    color: ${COLORS.green};
    font-size: 1.1em;
    font-weight: 550;
    /* background: #ffffff; */
    background: #ffffff;
    /* box-shadow: 10px 10px 20px #dbdbdb, -10px -10px 20px #ffffff; */
    box-shadow: 0.5px 0.5px 5px ${COLORS.checkOrder},
      -0.5px -0.5px 5px ${COLORS.checkOrder};
    cursor: pointer;
  }
  .box-to-btn-home > button:active {
    box-shadow: inset 5px 5px 13px #e6e6e6, inset -5px -5px 13px #ffffff;
  }
  .in-orders-current > ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0px auto;
    align-items: center;
    height: 70vh;
    overflow-y: scroll;
    /* background: green; */
  }
  .in-orders-current > ul::-webkit-scrollbar {
    display: none; /* Masquer la barre de défilement */
  }

  @media screen and (max-width: 445px) {
    margin-top: 10vh;
    .in-orders-current {
      .box-info-help {
        .box-icon-help-info {
          margin: 3px 10px;
        }
      }
    }
  }
`;
