export const vertDate = (value) => {
  const date = new Date(value);
  const now = new Date();

  const diffInMs = now.getTime() - date.getTime();
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);

  // Définition des références de jour (aujourd'hui et hier)
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  // Formatage de l'heure et de la date
  const heureExacte = date.toLocaleTimeString("fr-FR", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const jour = date.getDate().toString().padStart(2, "0"); // Ajoute un 0 si nécessaire
  const mois = (date.getMonth() + 1).toString().padStart(2, "0"); // Mois commence à 0
  const annee = date.getFullYear();

  if (date >= today) {
    // Gestion des minutes et heures pour aujourd'hui
    if (diffInMinutes < 1) {
      return "À l'instant";
    } else if (diffInMinutes < 60) {
      return `Il y a ${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""}`;
    } else if (diffInMinutes < 1440) {
      return `Il y a ${diffInHours} heure${diffInHours > 1 ? "s" : ""}`;
    }
  } else if (date >= yesterday && date < today) {
    return `Hier à ${heureExacte}`;
  } else {
    return `Le ${jour}/${mois}/${annee} à ${heureExacte}`;
  }
};
