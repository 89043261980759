import { Route, Routes } from "react-router-dom";
import HeaderApp from "./Components/Header/HeaderApp";
import FooterApp from "./Components/Footer/FooterApp";
import { Dynamic } from "./Context/ContextDynamic/ContextDynamic";
import Home from "./Pages/Home/Home";
import PopToChoose from "./utils/Popup/PopToChoose";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import Order from "./Pages/Order/Order";
import PrivateRouteHome from "./Private/RouteHome/PrivateRouteHome";
import Admin from "./Pages/Admin/Admin";
import PopGlobal from "./utils/Popup/PopGlobal";
import { Data } from "./Context/ContextData/ContextData";
import { OrderCurrent } from "./Context/ContextOrderCurrent/ContextOrderCurrent";
import PopJustMenus from "./utils/Popup/PopJustMenus";
import Loading from "./utils/Loading/Loading";
import PrivateRouteClientHome from "./Private/RouteClientHome/PrivateRouteClientHome";
import ClientHome from "./Pages/Clients/Home/ClientHome";
import Identification from "./Pages/Clients/Forms/Identification";
import Code from "./Pages/Code/Code";
import { MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";
import { COLORS } from "./utils/Style/styleGlobale";
function App() {
  const [showFoot, setShowFoot] = useState(false);
  const { popTableChoose, toPopMenus, loading } = Dynamic();
  const { dataToPopUp } = Data();
  const { orderAllUser } = OrderCurrent();

  const goPop = () => {
    if (popTableChoose) {
      return <PopToChoose />;
    }
  };

  // useEffect surveille si l'utilisateur actualise la page
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Vérifier si orderAllUser existe
      if (orderAllUser && orderAllUser.length > 0) {
        const message =
          "Attention : Une commande est en cours.. Si vous actualisez la page les données de la commande en cours seront perdues";
        event.returnValue = message; // Standard pour la plupart des navigateurs
        return message; // Pour les anciennes versions de Firefox
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [orderAllUser]);
  return (
    <StyledApp>
      <HeaderApp />
      {goPop()}
      {dataToPopUp && <PopGlobal />}
      {toPopMenus && <PopJustMenus />}
      {loading && <Loading />}
      <Routes>
        <Route
          path="/order"
          element={
            <PrivateRouteHome>
              <Order />
            </PrivateRouteHome>
          }
        ></Route>
        <Route
          path="/dashboard"
          element={
            <PrivateRouteHome>
              <Admin />
            </PrivateRouteHome>
          }
        ></Route>
        {/* <Route path="/code/id" element={<Code />}></Route> */}
        <Route
          path="/code/id"
          element={
            <PrivateRouteHome>
              <Code />
            </PrivateRouteHome>
          }
        ></Route>
        {/* <Route path="/client" element={<ClientHome />}></Route> */}
        <Route
          path="/client"
          element={
            <PrivateRouteClientHome>
              <ClientHome />
            </PrivateRouteClientHome>
          }
        ></Route>
        <Route
          path="/client/identification"
          element={<Identification />}
        ></Route>
        <Route path="*" element={<Home />}></Route>
      </Routes>
      <MdOutlineKeyboardDoubleArrowUp
        className="to-show-footer"
        onClick={() => {
          console.log("Clicked, changing showFoot state");
          setShowFoot((prev) => !prev);
        }}
      />
      {showFoot && <FooterApp />}
      <ToastContainer position="top-left" />
    </StyledApp>
  );
}

export default App;

const StyledApp = styled.div`
  height: 98vh;
  width: 100%;
  position: relative;
  .to-show-footer {
    position: fixed;
    z-index: 70;
    bottom: 10px;
    cursor: pointer;
    left: 10px;
    font-size: 2em;
    color: ${COLORS.btnColor};
  }
`;
