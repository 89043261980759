import React, { useState } from "react";
import styled from "styled-components";
import { Dynamic } from "../../../../Context/ContextDynamic/ContextDynamic";
import { toast } from "react-toastify";
import axios from "axios";
import { FaArrowCircleRight } from "react-icons/fa";
import { COLORS } from "../../../../utils/Style/styleGlobale";

const Register = () => {
  const [pseudo, setPseudo] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConf, setPasswordConf] = useState("");
  const [code, setCode] = useState("");
  const [acceptCgu, setAcceptCgu] = useState(false);
  const [registerWaiting, setRegisterWaiting] = useState(false);
  const { formConnexionRegister, setFormConnexionRegister } = Dynamic();
  const handleRegister = async (e) => {
    e.preventDefault();
    if (!acceptCgu)
      return toast.error(
        "Les Cgu et Politique de confidentialité doivent être acceptées pour continuer"
      );
    if (!pseudo || !email || !password || !code)
      return toast.error("Tous champs sont nécéssaires");
    if (password !== passwordConf)
      return toast.error("Mot de passe ne correspondent pas ");
    setRegisterWaiting(true);
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}auth/register`,
        withCredentials: true,
        data: {
          pseudo,
          email,
          password,
          code,
        },
      });
      // console.log(res);
      setRegisterWaiting(false);
      if (res.data.error) return toast.error(res.data.error);
      if (res.data.succes) {
        setPseudo("");
        setEmail("");
        setPassword("");
        setPasswordConf("");
        setCode("");
        return toast.success(res.data.succes);
      }
    } catch (error) {
      setRegisterWaiting(false);
      console.log(error);
      toast.error("Erreur lors de l'inscription");
    }
  };
  const clickToInsta = () => {
    const url = "https://www.instagram.com/noulinkg";
    window.open(url, "_blank");
  };
  return (
    <StyledRegister onSubmit={(e) => handleRegister(e)} $css={acceptCgu}>
      <h2>Vendeur</h2>
      <input
        type="text"
        placeholder="Nom business"
        onChange={(e) => setPseudo(e.target.value)}
        value={pseudo ? pseudo : ""}
      />
      <input
        type="email"
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
        value={email ? email : ""}
      />
      <input
        type="password"
        placeholder="Mot de passe"
        onChange={(e) => setPassword(e.target.value)}
        value={password ? password : ""}
      />
      <input
        type="password"
        placeholder="Confirmation du mot de passe"
        onChange={(e) => setPasswordConf(e.target.value)}
        value={passwordConf ? passwordConf : ""}
      />
      <input
        type="text"
        placeholder="Code d'inscription"
        onChange={(e) => setCode(e.target.value)}
        value={code ? code : ""}
      />
      <div className="box-accept-cgu">
        <input
          type="checkbox"
          className="input-checkbox"
          onChange={() => setAcceptCgu(!acceptCgu)}
        />
        <p>
          J'ai lu et acceptez les CGU et notre politique de confidentialité.
          "Voir plus" pour afficher les cgu
        </p>
      </div>
      {acceptCgu && (
        <input
          className="input-register"
          type="submit"
          value={registerWaiting ? "En cours ..." : "Inscription"}
        />
      )}
      <strong onClick={() => setFormConnexionRegister(!formConnexionRegister)}>
        {formConnexionRegister && "J'ai un compte "}
        <FaArrowCircleRight className="ico-arrow" />
      </strong>
      <span className="not-code-sub">
        Pas de code d'inscription ? Contactez-nous sur{" "}
        <span onClick={() => clickToInsta()}>Instagram : noulinkg</span>
      </span>
    </StyledRegister>
  );
};

export default Register;
const StyledRegister = styled.form`
  /* background: #749f68; */
  background: ${COLORS.green};
  display: flex;
  padding: 10px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  h2 {
    color: white;
    font-size: 2em;
  }
  input {
    width: 70%;
    padding: 5px;
    outline: none;
    border: none;
    margin-top: 10px;
    font-size: 1em;
    border-radius: 5px;
  }
  .input-register {
    width: 50%;
    cursor: pointer;
    /* background: #749f68; */
    background: ${COLORS.checkOrder};
    box-shadow: 2px 2px 5px ${COLORS.checkOrder},
      -2px -2px 5px ${COLORS.checkOrder};
    color: white;
  }
  .box-accept-cgu {
    padding: 0px;
    width: 100%;
    /* background: blue; */
    margin-top: 5px;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    .input-checkbox {
      width: 3%;
      margin: 0px;
    }
    p {
      margin: 0px;
      padding: 0px;
      font-size: 0.5em;
      color: ${({ $css }) => ($css ? COLORS.blackgrey : "orange")};
      text-align: center;
    }
  }
  strong {
    font-size: 1em;
    display: flex;
    cursor: pointer;
    color: ${COLORS.btnColor};
    align-items: center;
    margin-top: 20px;
    background: white;
    padding: 5px;
    border-radius: 10px;
    .icon-arrow {
      margin-right: 10px;
    }
  }
  .not-code-sub {
    margin-top: 25px !important;
    width: 100% !important;
    font-size: 0.8em;
    color: white !important;
    background: none !important;
    span {
      cursor: pointer;
      color: ${COLORS.checkOrder};
      background: none !important;
    }
  }

  //tablette 768px
  @media screen and (max-width: 768px) {
    width: 60%;
  }

  //width >= 425px
  @media screen and (max-width: 445px) {
    width: 90%;
    strong {
      font-size: 1em;
    }
    input {
      width: 90%;
      font-size: 1em;
    }
    .not-code-sub {
      margin-top: 25px !important;
      width: 100% !important;
      font-size: 0.8em;
      color: white;
      background: none !important;
      span {
        cursor: pointer;
        color: ${COLORS.checkOrder} !important;
        background: none !important;
      }
    }
  }
`;
