import React, { useEffect, useRef, useState } from "react";
import { Dynamic } from "../../../Context/ContextDynamic/ContextDynamic";
import styled from "styled-components";
import ButtonNav from "../../../utils/ButtonNav/ButtonNav";
import axios from "axios";
import { toast } from "react-toastify";
import { COLORS } from "../../../utils/Style/styleGlobale";
import PopProductsClientFast from "./PopProductsClientFast";
import { GoHomeFill } from "react-icons/go";
import { FaWindowClose } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import PopToseeStatusOrders from "./PopToseeStatusOrders";
import { SocketContext } from "../../../Context/ContextSocket/ContextSocket";
import { Data } from "../../../Context/ContextData/ContextData";
import { FaCircleInfo } from "react-icons/fa6";
import { CgProfile } from "react-icons/cg";
import { GiShoppingBag } from "react-icons/gi";
import PopInfoSeller from "./PopInfoSeller";
import { MdDeliveryDining } from "react-icons/md";
import PopIfDelivery from "./PopIfDelivery";

const ClientHome = () => {
  const [categories, setCategories] = useState([]);
  const [categorieSelect, setCategorieSelect] = useState("");
  const [popDisplayOrder, setPopDisplayOrder] = useState(false);
  const [displayPopStatusOrders, setDisplayPopStatusOrders] = useState(false);
  const [messageVide, setMessageVide] = useState("");
  const [countProductsAdd, setCountProductsAdd] = useState(0);
  const [orderFast, setOrderFast] = useState([]);
  const [ifNote, setIfNote] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [valideNoteText, setValideNoteText] = useState(false);
  const [totalPriceOrderCurrent, setTotalPriceOrderCurrent] = useState(0);
  const [popInfoUser, setPopInfoUser] = useState(false);
  const [toPopInfoUser, setToPopInfoUser] = useState({});
  const [displayPopDelivery, setDisplayPopDelivery] = useState(false);
  const [textDelivery, setTextDelivery] = useState("");
  const [telToDelivery, setTelToDelivery] = useState("");

  //ref to stock id setTimeOut
  const timeoutRef = useRef(null);
  const { goPage, cookies, removeCookie, setLoading, setCookie } = Dynamic();
  const { socket } = SocketContext();
  const { ordersSended, setOrdersSended } = Data();

  const getAllCategories = async () => {
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}public/all/categorie`,
        withCredentials: true,
        data: { id: cookies.accesClient.idComp },
      });
      if (res.data.error) {
        return toast.error(res.data.error);
      }
      if (res.data.succes) {
        return setMessageVide(res.data.succes);
      }
      if (res.data.length > 0) {
        return setCategories(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const displayCategorie = () => {
    if (categories && categories.length > 0) {
      return (
        <ul className="list-categories">
          <h2>Catégories</h2>
          {categories.map((categorie, index) => (
            <li
              key={index}
              onClick={() => {
                // Nettoyer le timeout précédent
                if (timeoutRef.current) {
                  clearTimeout(timeoutRef.current);
                }
                // Définir un nouveau timeout et stocker sa référence
                timeoutRef.current = setTimeout(() => {
                  setCategorieSelect(categorie.nameCategorie);
                }, 200);
              }}
            >
              {categorie.nameCategorie}
            </li>
          ))}
        </ul>
      );
    }
  };

  //fonction calcule total Order
  const calculateTotal = () => {
    let total = 0;
    orderFast.forEach((item) => {
      Object.values(item).forEach((products) => {
        products.forEach((product) => {
          total += product.price;
        });
      });
    });
    return total;
  };

  //display order added

  // Filtrer les catégories vides (supprimé les catégorie vide)
  const filteredOrdersFast = orderFast.filter((item) => {
    return Object.values(item).some((products) => products.length > 0);
  });
  const displayOrderAdded = () => {
    const updateNote = () => {
      setValideNoteText(false);
      setIfNote(true);
    };
    const valideNoteButton = () => {
      if (noteText.length > 50) return toast.error("50 caractères max");
      setIfNote(false);
      setValideNoteText(true);
    };
    const cancelButDisplayIfNoteExist = () => {
      if (ifNote) {
        setValideNoteText(false);
        setIfNote(false);
        setNoteText("");
      } else {
        setValideNoteText(false);
        setIfNote(true);
      }
    };

    //agit sur les commandes en cours
    const removeProduct = (category, name) => {
      const updatedOrdersAdded = orderFast.map((item) => {
        if (item[category]) {
          return {
            ...item,
            [category]: item[category].filter(
              (product) => product.name !== name
            ),
          };
        }
        return item;
      });
      // Mettre à jour l'état avec les commandes mises à jour
      setOrderFast(updatedOrdersAdded);
    };
    // Fonction pour calculer le sous-total de chaque catégorie
    const calculateSubtotal = (category) => {
      let subtotal = 0;
      orderFast.forEach((item) => {
        if (item[category]) {
          item[category].forEach((product) => {
            subtotal += product.price;
          });
        }
      });
      // console.log(subtotal);
      return subtotal.toFixed(2);
    };
    //fonction calcule total
    calculateTotal(orderFast);

    // // Filtrer les catégories vides (supprimé les catégorie vide)
    // const filteredOrdersFast = orderFast.filter((item) => {
    //   return Object.values(item).some((products) => products.length > 0);
    // });
    if (orderFast && orderFast.length > 0) {
      return (
        <div className="pop-order-added">
          <FaWindowClose
            className="close-option "
            onClick={() => setPopDisplayOrder(false)}
          />
          <div className="box-where-title-is">
            <h1>Votre listes de commande</h1>
          </div>
          {noteText && (
            <div className="text-writing">
              <span>Note : {noteText}</span>
            </div>
          )}
          {textDelivery && telToDelivery && (
            <div className="info-to-delivery">
              <strong>Pour la livraison :</strong>
              <p>Infos: {textDelivery && textDelivery}</p>
              <p>Tel : {telToDelivery && telToDelivery}</p>
            </div>
          )}
          <div className="box-to-total">
            <h2 className="h2-total">
              Total : {calculateTotal().toFixed(2)} €
            </h2>

            {toPopInfoUser.delivery !== 0 && (
              <button
                className="btn-add-delivery"
                onClick={() => setDisplayPopDelivery(!displayPopDelivery)}
              >
                Me faire livrer <MdDeliveryDining className="icon-delivery" />{" "}
              </button>
            )}
            <div className={ifNote ? "to-add-note large" : "to-add-note"}>
              {valideNoteText ? (
                <span onClick={() => updateNote()}>Modifier</span>
              ) : (
                <span onClick={() => cancelButDisplayIfNoteExist()}>
                  {ifNote ? "Annuler" : "Ajouter une note"}
                </span>
              )}
              {ifNote && (
                <div className="box-shield-text-note">
                  <textarea
                    placeholder="Ajouter une note spéciale pour votre commande"
                    value={noteText ? noteText : ""}
                    onChange={(e) => setNoteText(e.target.value)}
                  ></textarea>
                  {noteText && (
                    <button onClick={() => valideNoteButton()}>Ajouter</button>
                  )}
                  <span className={noteText.length > 50 ? "red" : "white"}>
                    {noteText.length} caractères (50 max)
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="box-to-ul-show-list-orders-added">
            {filteredOrdersFast.map((item, index) => (
              <ul key={index} className="ul-show-list">
                {Object.entries(item).map(([category, prods]) => (
                  <div key={category} className="box-h3-n-li">
                    <h3>
                      {category} - Sous-total : {calculateSubtotal(category)} €
                    </h3>
                    {prods.map((prod, index) => (
                      <li key={index}>
                        <div>
                          <span>{prod.name}</span>
                          <span>{prod.price.toFixed(2)} €</span>
                        </div>
                        <MdDelete
                          onClick={() => removeProduct(category, prod.name)}
                          className="btn-delete-one-prod"
                        />
                      </li>
                    ))}
                  </div>
                ))}
              </ul>
            ))}
          </div>
          <div className="space-last-btns">
            <button className="btn-send-in-cook" onClick={() => sendMyOrder()}>
              Envoyer
            </button>
          </div>
        </div>
      );
    } else {
      setPopDisplayOrder(false);
    }
  };

  //here to send
  // Fonction pour mettre à jour orderAllUser en supprimant les index vides
  const updateOrderAllUser = () => {
    // console.log(orderAllUser);
    // Parcourir chaque élément de orderAllUser
    for (let i = orderFast.length - 1; i >= 0; i--) {
      const item = orderFast[i];
      const keys = Object.keys(item);

      // Vérifier si l'index est vide
      if (keys.length > 0 && item[keys[0]].length === 0) {
        // Supprimer l'index vide
        orderFast.splice(i, 1);
      }
    }
  };
  //to send order in database mongDb
  const sendMyOrder = async () => {
    setLoading(true);
    updateOrderAllUser();
    if (!cookies.accesClient.nameClient && !cookies.accesClient.tableSelect) {
      setLoading(false);
      return toast.error("Des identifications sont nécessaires 🤧");
    }
    if (!cookies.accesClient.idComp || !cookies.accesClient.codefast) {
      setLoading(false);
      return toast.error("Des identifications importantes sont nécessaires 🤧");
    }
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}public/send/order`,
        withCredentials: true,
        data: {
          table: cookies.accesClient.tableSelect,
          idComp: cookies.accesClient.idComp,
          codefast: cookies.accesClient.codefast,
          nameClient: cookies.accesClient.nameClient,
          note: noteText,
          totalOrder: totalPriceOrderCurrent,
          order: orderFast,
          localisation: textDelivery,
          telephone: telToDelivery,
        },
      });
      // console.log(res);
      if (res.data.error) {
        setLoading(false);
        return toast.error(res.data.error);
      }
      //stop send company close
      if (res.data.stop) {
        setLoading(false);
        return toast.info(res.data.stop);
      }
      if (res.data.succes) {
        //envoi première commande du clientName
        setLoading(false);
        const expiration24Hour = new Date();
        const valueCookie = {
          idComp: cookies.accesClient.idComp,
          nameClient: cookies.accesClient.nameClient,
          tableSelect: cookies.accesClient.tableSelect,
          codefast: cookies.accesClient.codefast,
          companyName: cookies.accesClient.companyName,
          ordersend: true,
        };
        expiration24Hour.setHours(expiration24Hour.getHours() + 24);
        setCookie("accesClient", valueCookie, {
          expires: expiration24Hour,
          path: "/",
        });
        //on vide la panier
        setOrderFast([]);
        socket.emit("new-order-add", { id: cookies.accesClient.idComp });
        socket.off("new-order-add"); //nettoie l'evenement après avoir emi
        return toast.success("Commande envoyée 😎");
      }
      //plus d'1 commande avec le même nom (clientName)
      if (res.data.orderName) {
        setLoading(false);
        setOrdersSended(res.data.orderName);
        const expiration24Hour = new Date();
        const valueCookie = {
          idComp: cookies.accesClient.idComp,
          nameClient: cookies.accesClient.nameClient,
          tableSelect: cookies.accesClient.tableSelect,
          codefast: cookies.accesClient.codefast,
          companyName: cookies.accesClient.companyName,
          ordersend: true,
        };
        expiration24Hour.setHours(expiration24Hour.getHours() + 24);
        setCookie("accesClient", valueCookie, {
          expires: expiration24Hour,
          path: "/",
        });
        //on vide le panier
        setOrderFast([]);
        //on vide la note
        setNoteText("");

        socket.emit("new-order-add", { id: cookies.accesClient.idComp });
        socket.off("new-order-add"); //nettoie l'evenement après avoir emi
        socket.emit("data-front-playson", { id: cookies.accesClient.idComp });
        socket.off("data-front-playson");
        return toast.success("Commande envoyé 😎");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      return toast.error("Une erreur est survenue du côté serveur");
    }
  };

  const goHomeDeleteCookies = () => {
    if (
      window.confirm(
        "Vous allez perdre le suivi de votre commande envoyée et celle en cours, ok pour continuer 😎"
      )
    ) {
      removeCookie("accesClient");
      setOrderFast([]);
      goPage("/");
      window.location.reload();
    }
  };

  const btnToDisplayOrderAdded = () => {
    if (filteredOrdersFast.length === 0) {
      return toast.info("Votre panier est vide 🤧");
    } else {
      setPopDisplayOrder(true);
    }
  };

  const getOrdersExistWithNameOrTableClientFastCurrent = async () => {
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}public/get/order/cooking`,
        withCredentials: true,
        data: {
          nameClient: cookies.accesClient.nameClient,
          table: cookies.accesClient.tableSelect,
          idComp: cookies.accesClient.idComp,
        },
      });
      // console.log(res);
      if (res.data.orderName) {
        setOrdersSended(res.data.orderName);
      }
    } catch (error) {
      console.log(error);
      return toast.error("Une erreur est survenue 😮‍💨");
    }
  };

  //info time out session
  const infoTimeOutSession = () => {
    return toast.info("Session s'expire après 24h selon le navigateur");
  };

  //catch info on seller
  const getInfoSeller = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}public/get/seller/${cookies.accesClient.idComp}`,
        withCredentials: true,
      });
      // console.log(res);
      if (res.data.error) {
        return toast.error(res.data.error);
      }
      if (res.data.user) {
        setToPopInfoUser(res.data.user);
      }
    } catch (error) {
      console.log(error);
      return toast.error(
        "Une erreur est survenue lors du chargement des infos concernant votre vendeur"
      );
    }
  };
  useEffect(() => {
    getAllCategories();

    //vérifié si ya des commande en cours dans : setOrdersSended(())
    if (cookies.accesClient.idComp) {
      if (cookies.accesClient.nameClient || cookies.accesClient.tableSelect) {
        if (cookies.accesClient.ordersend) {
          getOrdersExistWithNameOrTableClientFastCurrent();
        }
      }
    }

    //get info seller
    getInfoSeller();
  }, []);
  let countTotal = 0;
  useEffect(() => {
    const countTotalCalcul = () => {
      for (let i = 0; i < orderFast.length; i++) {
        const element = orderFast[i];
        for (const cle in element) {
          if (Array.isArray(element[cle])) {
            countTotal += element[cle].length;
          }
          // console.log("Longueur autre :" + element.length);
        }
      }
      setCountProductsAdd(countTotal);
    };

    countTotalCalcul();
    // console.log(cookies.accesClient);

    //to catch to orderCurrent to send
    setTotalPriceOrderCurrent(calculateTotal());
  }, [orderFast]);

  return (
    <StyledClientHome $css={ifNote}>
      {/* {popInfoUser && displayPopToInfoUser()} */}
      {popInfoUser && (
        <PopInfoSeller
          setPopInfoUser={setPopInfoUser}
          popInfoUser={popInfoUser}
          toPopInfoUser={toPopInfoUser}
        />
      )}
      {categorieSelect && (
        <PopProductsClientFast
          setCategorieSelect={setCategorieSelect}
          categorieSelect={categorieSelect}
          setOrderFast={setOrderFast}
          orderFast={orderFast}
        />
      )}
      {displayPopStatusOrders && (
        <PopToseeStatusOrders
          ordersSended={ordersSended}
          setDisplayPopStatusOrders={setDisplayPopStatusOrders}
        />
      )}
      {displayPopDelivery && (
        <PopIfDelivery
          setDisplayPopDelivery={setDisplayPopDelivery}
          textDelivery={textDelivery}
          setTextDelivery={setTextDelivery}
          telToDelivery={telToDelivery}
          setTelToDelivery={setTelToDelivery}
        />
      )}
      <div className="box-list-categories">
        {messageVide && <h1>{messageVide}</h1>}
        <div className="infos-box-top">
          {cookies.accesClient.tableSelect && (
            <span onClick={() => infoTimeOutSession()}>
              Table :{" "}
              <strong>
                {cookies.accesClient.tableSelect}{" "}
                <FaCircleInfo className="icon-info" />
              </strong>
            </span>
          )}
          {cookies.accesClient.nameClient && (
            <span onClick={() => infoTimeOutSession()}>
              Pseudo* :
              <strong>
                {" "}
                {cookies.accesClient.nameClient}
                <FaCircleInfo className="icon-info" />{" "}
              </strong>
            </span>
          )}
          <span>
            Code utilisé :<strong>{cookies.accesClient.codefast}</strong>
          </span>
          <span className="fin-info-session">
            *La session peut être expirée après 24h selon le navigateur
          </span>
        </div>
        <div
          className="button-icon-see-current-shop"
          onClick={() => btnToDisplayOrderAdded()}
        >
          <GiShoppingBag className="icon-pack-shop-current" />
          <span>
            {orderFast.length > 0 ? "(" + countProductsAdd + ")" : ""}
          </span>
        </div>
        {ordersSended && ordersSended.length > 0 && (
          <div className="second-box-to-view-order">
            <ButtonNav
              text={"En cours..."}
              actionClick={() => setDisplayPopStatusOrders(true)}
            />
          </div>
        )}
        <GoHomeFill
          onClick={() => goHomeDeleteCookies()}
          className="home-red"
        />

        {cookies.accesClient.companyName && (
          <span
            className="box-span-to-profil"
            onClick={() => setPopInfoUser(!popInfoUser)}
          >
            <CgProfile className="i-profil-client" />
            <strong>{cookies.accesClient.companyName}</strong>
          </span>
        )}
      </div>
      {displayCategorie()}
      {popDisplayOrder && displayOrderAdded()}
    </StyledClientHome>
  );
};

export default ClientHome;
const StyledClientHome = styled.div`
  /* background: orange; */
  position: relative;
  height: 100%;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .box-list-categories {
    position: relative;
    margin-top: 10px;
    background: ${COLORS.greenTransparent};
    border-radius: 5px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px;
    .infos-box-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      span {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-info {
          font-size: 0.8em;
          color: ${COLORS.red};
        }
      }
      .fin-info-session {
        position: absolute;
        bottom: 0px;
        left: 5px;
        font-size: 0.5em;
      }
    }
    .box-span-to-profil {
      background: ${COLORS.green};
      cursor: pointer;
      /* width: 10%; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px;
      border-radius: 5px;
      margin: 0px auto;
      box-shadow: 2px 3px 5px ${COLORS.blackgrey};
      .i-profil-client {
        font-size: 4em;
        color: white;
      }
      strong {
        color: white;
      }
    }
  }
  .button-icon-see-current-shop {
    /* background: green; */
    /* width: 10%; */
    padding: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    cursor: pointer;
    .icon-pack-shop-current {
      font-size: 3em;
      color: ${COLORS.green};
      filter: drop-shadow(1px 3px 0.2rem ${COLORS.blackgrey});
    }
  }
  .info-to-delivery {
    border: dashed 1px white;
    padding: 5px;
    border-radius: 10px;
    strong {
      font-size: 0.9em;
    }
    p {
      font-size: 0.8em;
    }
  }
  .white {
    color: white !important;
  }
  .list-categories {
    margin-top: 20px;
    width: 50%;
    background: ${COLORS.green};
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 5px;
    h2 {
      width: 100%;
      text-align: center;
      color: white;
    }
    li {
      margin: 10px;
      padding: 5px;
      font-size: 1.2em;
      border-radius: 3px;
      color: white;
      cursor: pointer;
      background: ${COLORS.btnColor};
      box-shadow: 1px 1px 10px ${COLORS.btnColor},
        -1px -1px 10px ${COLORS.btnColor};
    }
    li:active {
      box-shadow: inset 11px 11px 22px ${COLORS.btnColor},
        inset -11px -11px 22px ${COLORS.btnColor};
    }
  }
  .home-red {
    font-size: 2em;
    color: ${COLORS.red};
    position: absolute;
    bottom: 20px;
    left: 5px;
    filter: drop-shadow(1px 3px 0.2rem ${COLORS.blackgrey});
  }

  //to display order added in panier
  .p-info {
    color: white;
    font-size: 0.7em;
    text-align: center;
  }
  .pop-order-added {
    position: fixed;
    height: 80vh;
    z-index: 80;
    width: 80%;
    background: ${COLORS.green};
    border-radius: 10px;
    padding: 5px 5px 10px 5px;
    border: solid 3px white;
    overflow-y: scroll;
    span,
    strong,
    p {
      color: white;
    }

    .box-to-total {
      margin: 5px 0px;
      display: flex;
      justify-content: space-around;
      h2 {
        display: flex;
        align-items: center;
      }
      .h2-total {
        color: #03d97d;
      }
    }
    .close-option {
      font-size: 1.5em;
      color: ${COLORS.red};
      cursor: pointer;
    }
    .box-where-title-is {
      h1 {
        /* margin-top: 10px; */
        text-align: center;
        color: white;
        margin-bottom: 10px;
      }
    }
    .btn-add-delivery {
      color: white;
      outline: none;
      /* margin-right: 20px; */
      text-align: center;
      border-radius: 5px;
      border: solid 2px white;
      cursor: pointer;
      padding: 5px;
      background: #03d97d;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.8em;
      .icon-delivery {
        margin-left: 3px;
        font-size: 2em;
      }
    }
    .btn-add-delivery:active {
      background: #03d97d;
      box-shadow: inset 11px 11px 22px #03b86a, inset -11px -11px 22px #03fa90;
    }
    .to-add-note {
      color: white;
      /* margin-right: 20px; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      border: solid 2px white;
      cursor: pointer;
      padding: 5px;
      background: #03d97d;
      /* box-shadow: 9px 8px 20px 1px #03b86a, -4px 0px 13px #03fa90; */
      font-size: 0.8em;
      span {
        color: ${({ $css }) => $css && "red"};
      }
      .box-shield-text-note {
        display: flex;
        flex-direction: column;
        width: 80%;
        textarea {
          width: 100%;
          resize: none;
          padding: 5px;
          height: 10vh;
          margin: 5px 0px;
          border-radius: 5px;
          outline: none;
        }
        button {
          margin: 0px auto;
          width: 50%;
          background: ${COLORS.green};
          border: none;
          outline: none;
          border-radius: 5px;
          color: white;
        }
      }
    }
    .large {
      /* width: 50% !important; */
      width: 50%;
      position: absolute;
    }
    .to-add-note:active {
      background: #03d97d;
      box-shadow: inset 11px 11px 22px #03b86a, inset -11px -11px 22px #03fa90;
    }
    .text-writing {
      background: orange;
      padding: 5px;
      span {
        font-size: 0.8em;
        color: white;
      }
    }
    .space-last-btns {
      /* background: pink; */
      display: flex;
      justify-content: center;
      align-items: center;
      /* border: solid 3px white; */
      border-radius: 5px;
    }
  }
  .pop-order-added::-webkit-scrollbar {
    display: none;
  }
  //👆

  .box-to-ul-show-list-orders-added {
    /* background: orange; */
    height: 60%;
    overflow-y: scroll;
    .ul-show-list {
      /* background: blueviolet; */
      margin: 5px 0px;
      .box-h3-n-li {
        h3 {
          font-size: 1.3em;
          padding: 5px 0px;
          background: ${COLORS.grey};
        }
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          /* background: green; */
          margin: 5px 0px;
          div {
            display: flex;
            justify-content: space-between;
            width: 90%;
            /* background: grey; */
            border-bottom: dashed 1px white;
            span {
              font-size: 1.1em;
            }
          }
          .btn-delete-one-prod {
            /* background: red; */
            color: ${COLORS.red};
            font-size: 1.5em;
            margin: 0px 10px;
          }
        }
      }
    }
  }
  .btn-send-in-cook {
    padding: 5px 20px;
    outline: none;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
    /* background: #03d97d; */
    background: ${COLORS.btnshadow};
    border: solid 2px orange;
    /* color: ${COLORS.blackgrey}; */
    color: white;
    /* box-shadow: 11px 11px 22px #03b86a, -11px -11px 22px #03fa90; */
    box-shadow: 1px 1px 20px ${COLORS.btnshadow},
      -1px -1px 20px ${COLORS.btnshadow};
  }
  .btn-send-in-cook:active {
    /* background: #03d97d; */
    box-shadow: inset 11px 11px 22px ${COLORS.btnshadow},
      inset -11px -11px 22px ${COLORS.btnshadow};
  }

  //btn 'commandes envoyées
  .second-box-to-view-order {
    margin: 10px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  //width >= 425px
  @media screen and (max-width: 445px) {
    .box-list-categories {
      margin-top: 5px;
      .box-span-to-profil {
        /* width: 50%; */
      }
    }
    .box-list-categories,
    .list-categories {
      width: 80%;
    }
    .pop-order-added {
      /* width: 95% !important; */
      width: 95%;
      height: 80vh;
      top: 70px;
      .box-where-title-is > h1 {
        font-size: 1.5em;
      }
      .large {
        /* width: 70% !important; */
        width: 90%;
      }
      .text-writing {
        margin: 10px 0px;
      }
      .to-add-note {
        .box-shield-text-note {
          textarea {
            height: 20vh;
          }
        }
      }
    }

    .box-to-total > h2 {
      /* font-size: 0.8em !important; */
      font-size: 0.9em;
      /* background: orange; */
      display: inline-block;
      padding: 5px;
      border-radius: 5px;
    }
    .box-to-total > .btn-add-delivery {
      margin: 0px;
      font-size: 0.7em;
    }
    .box-to-total > .to-add-note {
      margin: 0px;
      font-size: 0.7em;
    }
  }

  //width =< 425px
  /* @media screen and (min-width: 428px) {
    .box-span-to-profil {
    }
    .box-list-categories,
    .list-categories {
      width: 80%;
    }
    .pop-order-added {
      top: 70px;
      width: 90%;
      height: 80vh;
      .box-where-title-is > h1 {
        font-size: 1.5em;
      }
      .large {
        width: 70% !important;
      }
      .text-writing {
        margin: 10px 0px;
      }
    }
    .box-to-total > h2 {
      display: inline-block;
      padding: 5px;
      border-radius: 5px;
      font-size: 1.2em;
      background: orange;
    }

    .box-to-ul-show-list-orders-added > .ul-show-list > .box-h3-n-li > h3 {
      font-size: 1.1em;
    }
    .box-to-ul-show-list-orders-added
      > .ul-show-list
      > .box-h3-n-li
      li
      > div
      > span {
      font-size: 0.9em;
    }
  } */

  //width =< 360px
  /* @media screen and (min-width: 360px) {
    .box-list-categories,
    .list-categories {
      width: 90%;
    }
    .pop-order-added {
      top: 70px;
      width: 90%;
      height: 80vh;
      .box-where-title-is > h1 {
        font-size: 1.5em;
      }
      .large {
        width: 70%;
      }
      .text-writing {
        margin: 10px 0px;
      }
    }
    .box-to-total > h2 {
      display: inline-block;
      padding: 5px;
      border-radius: 5px;
      font-size: 1.2em;
      background: orange;
    }

    .box-to-ul-show-list-orders-added > .ul-show-list > .box-h3-n-li > h3 {
      font-size: 1.1em;
    }
    .box-to-ul-show-list-orders-added
      > .ul-show-list
      > .box-h3-n-li
      li
      > div
      > span {
      font-size: 0.9em;
    }
  } */
`;
