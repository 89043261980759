import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MdPayments } from "react-icons/md";
import { MdTableBar } from "react-icons/md";
import { FaGlobe } from "react-icons/fa6";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { FaCheckSquare } from "react-icons/fa";
import { FaThumbsUp } from "react-icons/fa6";
import { FaThumbsDown } from "react-icons/fa6";
import { IoHandLeftSharp } from "react-icons/io5";
import { MdDeliveryDining } from "react-icons/md";
import axios from "axios";
import { SocketContext } from "../../../../Context/ContextSocket/ContextSocket";
import { COLORS } from "../../../../utils/Style/styleGlobale";
import { Dynamic } from "../../../../Context/ContextDynamic/ContextDynamic";

import { BsFillTelephoneFill } from "react-icons/bs";
import { vertDate } from "../../../../utils/Fonctions";

const Order = ({
  codefast,
  idClientFast,
  table,
  note,
  order,
  idOrder,
  statusOrder,
  localisation,
  telephone,
  dateTime,
}) => {
  const { socket } = SocketContext();
  const { user, setTotalCostAll } = Dynamic();
  const deleteOrderClick = async (id, table, nameClient) => {
    let textTodelete;
    if (!id) return toast.error("Identification de la commande impossible");
    if (table) {
      textTodelete = `Voulez-vous vraiment supprimer la commande de la table ${table} ?`;
    } else if (nameClient) {
      textTodelete = `Voulez-vous vraiment supprimer la commande de ${nameClient} ?`;
    } else {
      textTodelete = "Supprimer ?";
    }
    if (window.confirm(textTodelete)) {
      try {
        const res = await axios({
          method: "delete",
          url: `${process.env.REACT_APP_API}order/delete/${id}`,
          withCredentials: true,
        });
        // console.log(res);
        if (res.data.succes) {
          socket.emit("order-delete", { id: user._id });
          socket.off("order-delete");
          return toast.success(res.data.succes);
        }
        if (res.data.error) return toast.error(res.data.error);
      } catch (error) {
        console.log(error);
        return toast.error("Une erreur est survenue");
      }
    }
  };

  const refusOrder = async (id, table, nameClient) => {
    let textToRefus;
    if (!id) return toast.error("Identification de la commande impossible");
    if (statusOrder === 0) {
      if (table) {
        textToRefus = `Refuser la commande de la table ${table} ?`;
      } else if (nameClient) {
        textToRefus = `Refuser la commande de ${nameClient} ?`;
      } else {
        textToRefus = "Refuser la commande";
      }
    }

    if (statusOrder === 3) {
      if (table) {
        textToRefus = `Remettre la commande de la table ${table} en attente ?`;
      } else if (nameClient) {
        textToRefus = `Remettre la commande de ${nameClient} en attente ?`;
      } else {
        textToRefus = "Remettre en attente ?";
      }
    }

    if (window.confirm(textToRefus)) {
      try {
        const res = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API}order/update/refus/${id}`,
          withCredentials: true,
        });
        // console.log(res);
        if (res.data.succes) {
          socket.emit("order-refus-to-client-target", {
            idClient: idClientFast,
            id: user._id,
          });
          socket.off("order-refus-to-client-target");
          socket.emit("order-valid", { id: user._id });
          socket.off("order-valid");
          return toast.success(res.data.succes);
        }
        if (res.data.error) return toast.error(res.data.error);
      } catch (error) {
        console.log(error);
        return toast.error("Une erreur est survenue");
      }
    }
  };

  const acceptOrder = async (id, table, name) => {
    let textToReady;
    if (!id) return toast.error("Identification de la commande impossible");
    if (statusOrder === 0) {
      if (table) {
        textToReady = `Accepter la commande de la table ${table} ?`;
      } else if (name) {
        textToReady = `Accepter la commande de ${name} ?`;
      } else {
        textToReady = "Accepter ?";
      }
    }

    if (statusOrder === 2) {
      if (table) {
        textToReady = `Remettre la commande de la ${table} en attente ?`;
      } else if (name) {
        textToReady = `Remettre la commande de ${name} en attente ?`;
      } else {
        textToReady = "Remettre en attente ?";
      }
    }

    if (window.confirm(textToReady)) {
      try {
        const res = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API}order/update/accept/${id}`,
          withCredentials: true,
        });
        // console.log(res);
        if (res.data.succes) {
          socket.emit("order-valid", { id: user._id });
          // socket.emit("valid-or-not-fast-client", {
          //   id: user._id,
          //   nameFast: idClientFast,
          // });
          socket.emit("valid-or-not-fast-client", {
            idClient: idClientFast,
          });
          socket.off("order-valid");
          socket.off("valid-or-not-fast-client");
          return toast.success(res.data.succes);
        }
        if (res.data.error) return toast.error(res.data.error);
      } catch (error) {
        console.log(error);
        return toast.error("Une erreur est survenue");
      }
    }
  };

  const validOrderClick = async (id) => {
    if (!id)
      return toast.error("Des identifiants sont obligatoire pour continuer");
    if (statusOrder === 0)
      return toast.error(
        "Vous devez accepter la commande avant de valider celle-ci"
      );
    if (statusOrder === 3)
      return toast.error("Les commandes refusées ne peuvent etre valider");
    let textOrder;
    if (statusOrder === 2) {
      textOrder = "Voulez-vous valider cette commande ? ";
    }
    if (statusOrder === 1) {
      textOrder = "Voulez-vous remetter cette commande en cours ? ";
    }
    if (window.confirm(textOrder)) {
      try {
        const res = await axios({
          method: "put",
          url: `${process.env.REACT_APP_API}order/update/${id}`,
          withCredentials: true,
        });
        // console.log(res);
        if (res.data.succes) {
          // console.log(res.data);
          socket.emit("order-valid", { id: user._id });
          // socket.emit("valid-or-not-fast-client", {
          //   id: user._id,
          //   nameFast: idClientFast,
          // });
          socket.emit("valid-or-not-fast-client", {
            idClient: idClientFast,
          });
          socket.off("order-valid");
          socket.off("valid-or-not-fast-client");
          return toast.success(res.data.succes);
        }
        if (res.data.error) return toast.error(res.data.error);
      } catch (error) {
        console.log(error);
        return toast.error("Une erreur est survenue");
      }
    }
  };
  // Fonction pour calculer le prix total de chaque commande
  const calculateTotalPrice = () => {
    let totalPrice = 0;
    const totalPricePerOrder = order.map((orde) => {
      Object.values(orde).forEach((products) => {
        products.forEach((product) => {
          // console.log(product);
          totalPrice += product.price;
        });
      });
      // return totalPrice.toFixed(2);
    });
    // setTotalPriceOrder(totalPrice);
    return totalPrice.toFixed(2);
    // console.log(totalPricePerOrder);
    // return totalPricePerOrder;
  };
  const displayListOrders = () => {
    const handleUlClickToChangeStyle = async (
      event,
      idOrder,
      order,
      category,
      item
    ) => {
      const ulElement = event.currentTarget;
      ulElement.classList.toggle("passed");
      // socket.emit("change-style", { idUser: user._id, idOrder: idOrder });
    };
    // console.log(order);
    return order.map((ord, index) => (
      <li key={index} className="li-box-parent-categorie">
        {Object.keys(ord).map((category) => (
          <div key={category}>
            <h3>{category}</h3>
            {ord[category].map((item, itemIndex) => (
              <div
                key={itemIndex}
                className="parent-ul-info-order"
                onClick={(e) =>
                  handleUlClickToChangeStyle(e, idOrder, order, category, item)
                }
              >
                {Object.keys(item).map((key) => (
                  <ul key={key} className="ul-info-order">
                    {/* <li className="li-others">
                      {item[key] !== item.price && item[key]}
                    </li> */}
                    {item[key] !== item.price &&
                      item[key] !== item.miniValid && (
                        <li className="li-others">{item[key]}</li>
                      )}
                    {item[key] === item.price && (
                      <li className="li-to-price">{item.price.toFixed(2)} €</li>
                    )}
                  </ul>
                ))}
              </div>
            ))}
          </div>
        ))}
      </li>
    ));
  };

  const searchAdressInMap = (address) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
    window.open(url, "_blank");
  };

  return (
    <StyledOrder $css={statusOrder}>
      <div className="title-to-legends">
        <div className="box-to-action-order">
          <div className="info-id-plus-date">
            {codefast ? (
              <span className="span-to-before-icon">
                <FaGlobe className="icon-def" />
                {idClientFast ? idClientFast : table} -{" "}
                <MdDelete
                  className="delete-order"
                  onClick={() => deleteOrderClick(idOrder, table, idClientFast)}
                />{" "}
              </span>
            ) : (
              <span className="span-to-before-icon">
                <MdTableBar className="icon-def" /> {table} -{" "}
                <MdDelete
                  className="delete-order"
                  onClick={() => deleteOrderClick(idOrder, table, idClientFast)}
                />{" "}
              </span>
            )}
            <span className="info-arrived">{vertDate(dateTime)}</span>
            <span>
              {" "}
              {statusOrder === 0
                ? "En attente"
                : statusOrder === 1
                ? "Valider"
                : statusOrder === 2
                ? "Accepter"
                : statusOrder === 3
                ? "Refuser"
                : "En attente"}
            </span>
          </div>
          {statusOrder !== 1 && (
            <IoHandLeftSharp
              className="refus-order"
              onClick={() => refusOrder(idOrder, table, idClientFast)}
            />
          )}
          {statusOrder !== 2 && statusOrder === 0 && (
            <FaThumbsUp
              className="ready-order"
              onClick={() => acceptOrder(idOrder, table, idClientFast)}
            />
          )}
          {statusOrder === 2 && statusOrder !== 0 && (
            <FaThumbsDown
              className="ready-order"
              onClick={() => acceptOrder(idOrder, table, idClientFast)}
            />
          )}
          {statusOrder !== 3 && (
            <FaCheckSquare
              className="valid-order"
              onClick={() => validOrderClick(idOrder)}
            />
          )}
          <strong>
            <MdPayments /> * {calculateTotalPrice()} €
          </strong>
        </div>
        {note && <p className="if-Note">Note : {note}</p>}
        <div className="box-to-seller-info-to-livraison">
          {localisation && (
            <p
              className="info-to-delivery"
              onClick={() => searchAdressInMap(localisation)}
            >
              <MdDeliveryDining className="ico-delivery" /> - {localisation}
            </p>
          )}
          {telephone && (
            <a href={`tel:${telephone}`} className="to-call">
              <BsFillTelephoneFill className="ico-tel" /> {telephone}
            </a>
          )}
        </div>
        <div className="display-boucl">{displayListOrders()}</div>
      </div>
    </StyledOrder>
  );
};

export default Order;

const StyledOrder = styled.div`
  width: 45%;
  height: 45vh;
  overflow-x: scroll;
  background: #404040;
  background: ${({ $css }) => ($css === 1 ? COLORS.blackgrey : "#404040")};
  padding-bottom: 5px;
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  /* position: relative; */
  &::-webkit-scrollbar {
    display: none;
  }
  .title-to-legends {
    width: 100%;
    position: relative;
    .box-to-seller-info-to-livraison {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
      .to-call,
      .info-to-delivery {
        display: flex;
        align-items: center;
        padding: 3px 10px;
        border-radius: 10px;
        font-size: 0.8em;
        cursor: pointer;
        color: white;
        /* background: white; */
        background: orange;
        box-shadow: 1px 2px 3px rgb(249, 146, 68);
        user-select: all;
        -webkit-user-select: auto;
        .ico-tel {
          font-size: 1.5em;
          margin-right: 5px;
        }
      }
      .info-to-delivery {
        background: #03d97d;
        color: ${COLORS.btnshadow};
        box-shadow: 1px 2px 3px #03d97d !important;
        .ico-delivery {
          font-size: 1.5em;
          margin-right: 5px;
        }
      }
    }
  }
  .if-Note {
    padding: 3px;
    background: ${COLORS.red};
    text-align: center;
  }
  .title-to-legends > .box-to-action-order {
    position: sticky;
    z-index: 50;
    /* background: ${({ $css }) =>
      $css === 2 ? COLORS.facebook : "#404040"}; */
    background: ${({ $css }) =>
      $css === 2 ? COLORS.facebook : $css === 3 ? COLORS.red : "#404040"};
    width: 100%;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    .info-id-plus-date {
      display: flex;
      flex-direction: column;
      background: pink;
      padding: 5px;
      border-radius: 5px;
      .span-to-before-icon {
        .icon-def {
          margin-right: 5px;
        }
        display: flex;
        align-items: center;
      }
      .delete-order {
        font-size: 1em;
        color: ${COLORS.red};
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
  .the-glob-to-order-by-internet {
    margin-right: 5px;
  }
  .parent-ul-info-order {
    margin: 5px;
    display: flex;
    position: relative;
    flex-direction: column;
  }
  .display-boucl {
    height: 100%;
    width: 100%;
  }
  .li-box-parent-categorie {
    margin-bottom: 5px;
    border-bottom: dashed 2px orange;
    padding: 5px;
    h3 {
      color: orange;
    }
    .li-others {
      margin-bottom: 3px;
      color: white;
    }
    .ul-info-order {
      background: ${COLORS.greenTransparent};
      display: flex;
      padding: 3px;
      flex-direction: row;
      /* position: relative; */
      /* li {
        color: ${COLORS.green};
      } */
    }
    .passed {
      background: grey !important;
    }
    .li-to-price {
      position: absolute;
      top: 0px;
      right: 0px;
      /* margin-left: 10px; */
      padding: 3px;
      text-align: center;
      /* width: 25%; */
      font-size: 0.7em;
      border-radius: 0px 0px 0px 3px;
      background: orange;
    }
  }
  .title-to-legends > .box-to-action-order > strong {
    background: ${COLORS.greenTransparent};
    color: white;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 3px;
  }
  .title-to-legends > .box-to-action-order > .refus-order {
    font-size: 2em;
    color: ${COLORS.red};
    background: #404040;
    /* box-shadow: 6px 6px 11px #363636, -6px -6px 11px #4a4a4a; */
    box-shadow: 6px 6px 11px
        ${({ $css }) => ($css === 2 ? COLORS.facebook : "#4a4a4a")},
      -6px -6px 11px ${({ $css }) => ($css === 2 ? COLORS.facebook : "#4a4a4a")};
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  .valid-order {
    font-size: 2em;
    /* color: ${COLORS.green}; */
    color: ${COLORS.checkOrder};
    background: #404040;
    /* box-shadow: 6px 6px 11px  #363636, -6px -6px 11px #4a4a4a; */
    box-shadow: 6px 6px 11px
        ${({ $css }) => ($css === 2 ? COLORS.facebook : "#363636")},
      -6px -6px 11px ${({ $css }) => ($css === 2 ? COLORS.facebook : "#4a4a4a")};
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  .ready-order {
    font-size: 2em;
    color: ${COLORS.facebook};
    background: #404040;
    box-shadow: 6px 6px 11px
        ${({ $css }) => ($css === 2 ? COLORS.facebook : "#363636")},
      -6px -6px 11px ${({ $css }) => ($css === 2 ? COLORS.facebook : "#4a4a4a")};
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
  }

  .valid-order:active {
    box-shadow: inset 20px 20px 60px #363636, inset -20px -20px 60px #4a4a4a;
  }
  .title-to-legends {
    .delete-order:active {
      box-shadow: inset 20px 20px 60px #363636, inset -20px -20px 60px #4a4a4a;
    }
  }

  //width >= 800px
  @media screen and (max-width: 800px) {
    .box-to-action-order > span > .the-glob-to-order-by-internet {
      font-size: 1.1em;
    }
    .title-to-legends > .box-to-action-order > .delete-order {
      font-size: 1.5em;
      padding: 2px;
    }
    .ready-order,
    .valid-order {
      font-size: 1.5em;
      padding: 2px;
    }
  }

  //width =< 428px
  @media screen and (max-width: 445px) {
    width: 90%;
    .title-to-legends {
      .box-to-seller-info-to-livraison {
        padding: 5px;
        .to-call,
        .info-to-delivery {
          border-radius: 5px;
          margin-top: 10px;
        }
      }
      .box-to-action-order {
        .info-id-plus-date {
          .info-arrived {
            font-size: 0.8em;
          }
        }
      }
    }
  }
`;
