import React, { useEffect, useState } from "react";
import Connexion from "../Connexion/Connexion";
import styled from "styled-components";
import Register from "../Register/Register";
import { Dynamic } from "../../../../Context/ContextDynamic/ContextDynamic";
import { COLORS } from "../../../../utils/Style/styleGlobale";
import ButtonNav from "../../../../utils/ButtonNav/ButtonNav";
import Presentation from "../../Presentation/Presentation";

const ModaleConnexionOrRegister = () => {
  const { formConnexionRegister, setFormConnexionRegister, goPage } = Dynamic();

  const [resetPassword, setResetPassword] = useState(false);
  useEffect(() => {
    if (formConnexionRegister) {
      setResetPassword(false);
    }
  }, [formConnexionRegister]);
  return (
    <StyledModaleConnexionOrRegister>
      <div className="div-to-connexion">
        <div className="button-visit-client">
          <ButtonNav
            text={"Je suis client(e) 👉"}
            actionClick={() => goPage("/client")}
          />
          {/* <span>👇Vendeur👇</span> */}
        </div>
        {formConnexionRegister ? (
          <Register />
        ) : (
          <Connexion
            resetPassword={resetPassword}
            setResetPassword={setResetPassword}
          />
        )}
        {!formConnexionRegister && (
          <span onClick={() => setResetPassword(!resetPassword)}>
            {resetPassword ? "Retour" : "Mot de passe oublié ?"}
          </span>
        )}
      </div>
      <Presentation />
    </StyledModaleConnexionOrRegister>
  );
};

export default ModaleConnexionOrRegister;

const StyledModaleConnexionOrRegister = styled.div`
  height: 90vh;
  /* background: url("./items/bgindex.jpg"); */
  background: ${COLORS.greenTransparent};
  background-position: center;
  /* overflow-x: scroll; */
  .div-to-connexion {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    span {
      background: ${COLORS.grey};
      width: 50%;
      margin-top: 5px;
      padding: 5px;
      border-radius: 5px;
      text-align: center;
      color: ${COLORS.red};
    }
  }
  .button-visit-client {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    span {
      padding: 0px;
      background: none;
      width: 100%;
      color: ${COLORS.green};
    }
  }
`;
