import React, { useEffect } from "react";
import { FaWindowClose } from "react-icons/fa";
import { IoIosInformationCircle } from "react-icons/io";
import styled from "styled-components";
import { COLORS } from "../../../utils/Style/styleGlobale";
import { MdLockClock } from "react-icons/md";
import { FaClock } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa6";
import OrderSendedToSeeInPop from "./OrderSendedToSeeInPop";
import { MdPayments } from "react-icons/md";
import { MdDeliveryDining } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { BsFillTelephoneFill } from "react-icons/bs";
const PopToseeStatusOrders = ({ ordersSended, setDisplayPopStatusOrders }) => {
  const formatDate = (dateCatch) => {
    const date = new Date(dateCatch);
    const maintenant = new Date();

    // Calculer la différence de temps en millisecondes
    const differenceEnMillisecondes = maintenant - date;

    // Convertir la différence en minutes
    const differenceEnMinutes = Math.floor(
      differenceEnMillisecondes / (1000 * 60)
    );

    if (differenceEnMinutes < 1) {
      return "À l'instant";
    } else if (differenceEnMinutes === 1) {
      return "Il y a 1 minute";
    } else if (differenceEnMinutes < 60) {
      return `Il y a ${differenceEnMinutes} minutes`;
    } else if (differenceEnMinutes < 120) {
      return "Il y a 1 heure";
    } else if (differenceEnMinutes < 1440) {
      return `Il y a ${Math.floor(differenceEnMinutes / 60)} heures`;
    } else {
      return `Il y a ${Math.floor(differenceEnMinutes / 1440)} jours`;
    }
  };
  return (
    <StyledPopToseeStatusOrders
      onClick={() => setDisplayPopStatusOrders(false)}
    >
      <FaWindowClose
        className="close-pop-status-orders"
        onClick={() => setDisplayPopStatusOrders(false)}
      />
      <h2>Commande(s) envoyé(s)</h2>
      <ul onClick={(e) => e.stopPropagation()}>
        {ordersSended &&
          ordersSended.length > 0 &&
          ordersSended.map((order) => (
            <div className="box-to-listing-orders-sended" key={order._id}>
              <div
                className={
                  order.status === 2 || order.status === 1
                    ? "header-infos-util valide"
                    : "header-infos-util"
                }
              >
                <li>
                  <FaGlobe />{" "}
                  {order.idClientFast ? order.idClientFast : order.table}{" "}
                </li>
                <li
                  className={
                    order.status === 1 || order.status === 2
                      ? "to-status-order"
                      : order.status === 3
                      ? "to-refus-order"
                      : ""
                  }
                >
                  <IoIosInformationCircle />{" "}
                  {order.status === 0
                    ? "En attente"
                    : order.status === 1
                    ? "Prête"
                    : order.status === 2
                    ? "Accepter"
                    : order.status === 3
                    ? "Refuser"
                    : "En attente"}
                </li>
                <li>
                  <MdLockClock /> {order.codefast}
                </li>
                <li className="total-info">
                  <MdPayments />
                  {order.totalOrder.toFixed(2)}€
                </li>
                <li>
                  <FaClock /> {formatDate(order.createdAt)}{" "}
                </li>
              </div>
              {order.note && (
                <span className="note-sended">Note : {order.note}</span>
              )}
              {order.localisation && (
                <div className="box-info-delivery">
                  <strong>
                    Infos pour la livraison{" "}
                    <MdDeliveryDining className="icon-d delievry" />{" "}
                  </strong>
                  <p>
                    <IoLocationSharp className="icon-d localisation" />{" "}
                    {order.localisation}
                  </p>
                  <p>
                    <BsFillTelephoneFill className="icon-d tel" />
                    {order.telephone}
                  </p>
                </div>
              )}
              <OrderSendedToSeeInPop order={order.order} />
            </div>
          ))}
      </ul>
    </StyledPopToseeStatusOrders>
  );
};

export default PopToseeStatusOrders;
const StyledPopToseeStatusOrders = styled.div`
  background: ${COLORS.greenTransparent};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: absolute;
  z-index: 40;
  height: 80%;
  width: 80%;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .close-pop-status-orders {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 1.5em;
    color: ${COLORS.red};
    cursor: pointer;
  }
  ul {
    width: 90%;
    overflow-y: scroll;
    margin-top: 30px;
    .box-to-listing-orders-sended {
      display: flex;
      flex-direction: column;
      background: ${COLORS.green};
      margin: 10px 0px;
      padding: 5px;
      border-radius: 10px;
      border: solid 3px white;
      width: 100%;
      .box-info-delivery {
        margin: 5px auto;
        background: white;
        padding: 5px;
        border-radius: 5px;
        .icon-d {
          font-size: 1.1em;
          color: ${COLORS.green};
          margin: 0px 5px;
        }
        strong {
          font-size: 0.9em;
          display: flex;
          align-items: center;
          margin: 0px 0px 5px 0px;
        }
        p {
          font-size: 0.8em;
        }
      }
      .header-infos-util {
        display: flex;
        justify-content: space-between;
        border-bottom: solid 2px white;
        ${({ $css }) =>
          $css === 2 || $css === 1 ? COLORS.validOnBlue : "white"};
        .total-info {
          padding: 0px 5px;
          background: orange;
          border-radius: 3px;
        }
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 1.3em;
        }
        .to-status-order {
          /* color: #037ad9; */
          /* color: #30ac64; */
          color: ${COLORS.validOnBlue};
        }
        .to-refus-order {
          color: ${COLORS.red};
        }
      }
      .valide {
        border-bottom: solid 2px ${COLORS.validOnBlue};
      }
      .note-sended {
        width: 100%;
        background: orange;
        padding: 5px;
        margin-top: 5px;
        border-radius: 3px;
      }
    }
  }

  //width >= 425px
  @media screen and (max-width: 445px) {
    width: 95%;
    ul {
      width: 100%;
      .header-infos-util {
        flex-wrap: wrap;
        align-items: flex-start;
        li {
          font-size: 0.7em;
          margin: 5px 0px;
        }
      }
    }
  }
`;
