import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { COLORS } from "../../../../utils/Style/styleGlobale";

const FormAddProduct = ({
  categories,
  setCategories,
  callGetCategoriesAgain,
}) => {
  const [selectCategorie, setSelectCategorie] = useState("");
  const [product, setProduct] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const FormAddProduct = async (e) => {
    e.preventDefault();
    if (!selectCategorie || !product || !price)
      return toast.error("Certains sont nécessaires");
    //check if a good format to price
    if (/^\d*\.?\d*$/.test(price)) {
      try {
        const res = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API}product/add`,
          withCredentials: true,
          data: {
            name: product,
            categorie: selectCategorie,
            info: description,
            price,
          },
        });
        // console.log(res);
        if (res.data.succes) {
          toast.success(res.data.succes);
          setProduct("");
          setDescription("");
          setPrice("");
        }
        if (res.data.error) return toast.error(res.data.error);
      } catch (error) {
        console.log(error);
        toast.error("Une erreur est survenue");
      }
    } else {
      return toast.error("Format du prix non attendu");
    }
  };
  useEffect(() => {
    const getAllCategorie = async () => {
      try {
        const res = await axios({
          method: "get",
          url: `${process.env.REACT_APP_API}categorie/all`,
          withCredentials: true,
        });
        setCategories(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllCategorie();
  }, [callGetCategoriesAgain]);
  return (
    <StyledFormAddProduct>
      <h1>Création d'un produit*</h1>
      <span className="span-info-actif">
        *Par défaut, les produits ajoutés sont inactifs
      </span>
      <form onSubmit={(e) => FormAddProduct(e)}>
        <div className="box-add-product">
          <select onChange={(e) => setSelectCategorie(e.target.value)}>
            <option value="">--Catégorie--</option>
            {categories &&
              categories.length > 0 &&
              categories.map((categorie) => {
                return (
                  <option value={categorie.nameCategorie} key={categorie._id}>
                    {categorie.nameCategorie}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="box-add-product produit-add">
          <input
            type="text"
            placeholder="Nom de votre produit (avec composants)"
            value={product ? product : ""}
            onChange={(e) => setProduct(e.target.value)}
          />
          <span>Ex: Sandwich (poulet, salade, piment etc)</span>
        </div>
        <div className="box-add-product box-description">
          <textarea
            placeholder="Donnez le choix au client(Optionnelle*)"
            onChange={(e) => setDescription(e.target.value)}
            value={description ? description : ""}
          ></textarea>
          <div className="to-exempl">
            <span>*Ex : </span>
            <p> Nom du produit : Martini</p>
            <p>Choix : Rouge ou blanc</p>
          </div>
        </div>
        <div className="box-add-product">
          <input
            type="text"
            placeholder="00.00"
            className="to-price"
            value={price ? price : ""}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <div className="box-add-product">
          <input type="submit" value="Ajouter" className="to-btn-send" />
        </div>
      </form>
    </StyledFormAddProduct>
  );
};

export default FormAddProduct;
const StyledFormAddProduct = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  h1 {
    text-align: center;
    font-size: 1.5em;
  }
  .span-info-actif {
    font-size: 0.9em;
    color: yellow;
  }
  form {
    border: solid 2px white;
    border-radius: 10px;
    padding: 10px;
    width: 50%;
    /* height: 50vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  form > .box-add-product {
    width: 100%;
    span {
      font-size: 0.9em;
      color: white;
    }
  }
  form > .produit-add {
    background: ${COLORS.green};
    padding: 5px;
    margin: 5px 0px;
  }
  form > .box-description {
    padding: 5px;
    margin-bottom: 10px;
    background: ${COLORS.greenTransparent};
  }
  .to-exempl > span {
    color: black !important;
  }
  .to-exempl > span,
  p {
    font-size: 0.7em;
  }
  form > .box-add-product > textarea {
    width: 100%;
    outline: none;
    border: none;
    font-size: 1.1em;
    /* margin-bottom: 5px; */
    border-radius: 5px;
    padding: 5px;
  }
  form > .box-add-product > input,
  form > .box-add-product > select {
    width: 100%;
    outline: none;
    border: none;
    font-size: 1.1em;
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 5px;
  }
  form > .box-add-product > textarea {
    height: 10vh;
    resize: none;
  }
  form > .box-add-product > .to-price {
    width: 20%;
  }
  form > .box-add-product > .to-btn-send {
    width: 50%;
    margin-top: 10px;
    background: #bab5b5;
    box-shadow: 7px 7px 15px #979393, -7px -7px 15px #ddd7d7;
  }
  form > .box-add-product > .to-btn-send:active {
    background: #bab5b5;
    box-shadow: inset 7px 7px 15px #979393, inset -7px -7px 15px #ddd7d7;
  }
  //width >= 425px
  @media screen and (max-width: 429px) {
    margin-bottom: 20px;
    h1 {
      margin-top: 5px;
      font-size: 1em;
    }
    .span-info-actif {
      font-size: 0.7em;
      color: yellow;
    }
    form {
      width: 90%;
      input {
        font-size: 0.9em !important;
      }
    }
  }
  //width =< 425px
  @media screen and (min-width: 425px) {
    /* margin-bottom: 20px; */
    /* h1 {
      margin-top: 5px;
      font-size: 1em;
    }
    .span-info-actif {
      font-size: 0.7em;
      color: yellow;
    }
    form {
      width: 90%;
    } */
  }
`;
